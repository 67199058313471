import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Pages } from 'consts';
import { Menu, SearchForm } from 'view/components';

import MenuIcon from './MenuIcon';

import './Header.scss';
import './Header.responsive.scss';

const mainPages = [
  Pages.AboutUs, Pages.Goals, /* Pages.Actual, */Pages.Organization, Pages.Contact,
];

const subPages = [...mainPages, Pages.Activities/* , Pages.Gallery */];

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);
  const onMenuClick = () => setMenuOpen((prevOpen) => !prevOpen);

  return (
    <div className="header">
      <div className="logo">
        <NavLink to="/">
          <img src="/resources/images/logo-with-text.png" alt="logo" />
        </NavLink>
      </div>
      {Pages && (
        <div className="menu-wrapper">
          {subPages && (
            <button className={clsx('menu-button', { opened: menuOpen })} type="button" onClick={onMenuClick}>
              <MenuIcon />
              {menuOpen && (
                <Menu className="submenu" items={subPages} />
              )}
            </button>
          )}
          <Menu className="header-menu" items={mainPages} />
          <SearchForm />
        </div>
      )}
    </div>
  );
};

export default Header;
