import React from 'react';
import PropTypes from 'prop-types';

import { ImagePropType } from 'consts';
import { getCroppingImageStyles, getImageUrl } from 'utils';
import PostLink from '../PostLink/PostLink';

import './PostImage.scss';

const PostImage = ({ image, imageStyles, crop, col, slug, segment, redirectUrl }) => {
  const className = `post-image post-image-col-${col}-${crop}`;
  const innerImage = (
    <div
      className="inner-image"
      style={{ backgroundImage: `url(${getImageUrl(image, getCroppingImageStyles(imageStyles, `${crop}Crop`), `tile-col-${col}`)})` }}
    />
  );

  if (slug) {
    return <PostLink className={className} slug={slug} segment={segment} redirectUrl={redirectUrl}>{innerImage}</PostLink>;
  }

  return <div className={className}>{innerImage}</div>;
};

PostImage.propTypes = {
  image: ImagePropType.isRequired,
  imageStyles: PropTypes.string,
  crop: PropTypes.string,
  col: PropTypes.number,
  slug: PropTypes.string,
  segment: PropTypes.string,
  redirectUrl: PropTypes.string,
};

PostImage.defaultProps = {
  imageStyles: {},
  crop: 'narrow',
  col: 1,
  slug: undefined,
  segment: undefined,
  redirectUrl: undefined,
};

export default PostImage;
