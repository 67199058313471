import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { CategoryPropType, ImagePropType, TagPropType } from 'consts';
import { calcCategoryColor, getDisplayDate, getPostUrl } from 'utils';
import { PostImage, PostLink, PostVideo } from 'view/base';

import './PostTile.scss';
import './PostTile.responsive.scss';

const imageSizes = [{
  col: 1,
  crop: 'narrow',
}];

const PostTile = ({
  className, slug, segment, title, lead, categories, image, imageStyles, redirectUrl, attributes, approvedAt, updatedAt, createdAt,
}) => {
  const category = categories[0];
  const videoUrl = attributes?.videoUrl;
  const noImage = !image && !videoUrl;
  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);
  const categoryColor = (categories && calcCategoryColor(category, categories)) || undefined;

  return (
    <article className={clsx('post-tile', className, { 'no-category': !category, 'no-title': title.substr(0, 3) === '###', 'no-image': noImage })}>
      {
        (videoUrl && <PostVideo url={videoUrl} />) ||
        (image && imageSizes.map(({ col, crop }) => (
          <PostImage key={col + crop} image={image} imageStyles={imageStyles} crop={crop} col={col} slug={slug} redirectUrl={redirectUrl} />
        )))
      }
      <div className="post-tile-content">
        {/* category && <CategoryLink className="category" category={category} showArrow /> */}
        <h1><PostLink className="post-title" slug={slug} segment={segment} redirectUrl={redirectUrl} style={{ color: categoryColor }}>{title}</PostLink></h1>
        {displayDate && <div className="post-date">{displayDate}</div>}
        {lead && (
          <h2 className="post-lead">
            {lead}
            <div className="read-more">
              <NavLink to={getPostUrl({ slug, segment })} style={{ color: categoryColor }}>&raquo;</NavLink>
            </div>
          </h2>
        )}
        <div className="read-more">
          <NavLink to={getPostUrl({ slug, segment })} style={{ color: categoryColor }}>Tovább olvasom &raquo;</NavLink>
        </div>
      </div>
    </article>
  );
};

PostTile.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  slug: PropTypes.string.isRequired,
  segment: PropTypes.string,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string,
  content: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  image: ImagePropType,
  imageStyles: PropTypes.string,
  source: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  redirectUrl: PropTypes.string,
  tags: PropTypes.arrayOf(TagPropType), // eslint-disable-line react/no-unused-prop-types
  categories: PropTypes.arrayOf(CategoryPropType),
  attributes: PropTypes.shape({
    videoUrl: PropTypes.string,
  }),
  approvedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,

};

PostTile.defaultProps = {
  className: '',
  segment: undefined,
  lead: '',
  content: '',
  source: '',
  redirectUrl: '',
  tags: [],
  categories: [],
  image: undefined,
  imageStyles: undefined,
  attributes: {},
  approvedAt: null,
  updatedAt: null,
  createdAt: null,

};

export default PostTile;
