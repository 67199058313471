const Pages = {
  Home: {
    title: 'Kezdőoldal',
    route: {
      path: '/',
      exact: true,
    },
    component: {
      type: 'MainPage',
    },
  },

  AboutUs: {
    title: 'Rólunk',
    mobileOnly: true,
    sitemap: {
      slug: 'rolunk',
    },
    route: {
      path: '/rolunk',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'rolunk' } },
        skipBackground: true,
        skipBreadcumb: true,
        skipHeader: true,
      },
    },
  },

  Goals: {
    title: 'Céljaink',
    mobileOnly: true,
    sitemap: {
      slug: 'celjaink',
    },
    route: {
      path: '/celjaink',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'celjaink' } },
        skipBackground: true,
        skipBreadcumb: true,
        skipHeader: true,
      },
    },
  },

  Organization: {
    title: 'Szervezet',
    mobileOnly: true,
    sitemap: {
      slug: 'szervezet',
    },
    route: {
      path: '/szervezet',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'szervezet' } },
        skipBackground: true,
        skipBreadcumb: true,
        skipHeader: true,
      },
    },
  },

  Contact: {
    title: 'Kapcsolat',
    mobileOnly: true,
    sitemap: {
      slug: 'kapcsolat',
    },
    route: {
      path: '/kapcsolat',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'kapcsolat' } },
        skipBackground: true,
        skipBreadcumb: true,
        skipHeader: true,
      },
    },
  },

  Impressum: {
    title: 'Impresszum',
    sitemap: {
      slug: 'impresszum',
    },
    route: {
      path: '/impresszum',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'impresszum' } },
        skipBackground: true,
        skipBreadcumb: true,
        skipHeader: true,
      },
    },
  },

  PrivacyInfo: {
    title: 'Adatvédelmi tájékoztató',
    sitemap: {
      slug: 'adatvedelmi-tajekoztato',
    },
    route: {
      path: '/adatvedelmi-tajekoztato',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'adatvedelmi-tajekoztato' } },
        skipBackground: true,
        skipBreadcumb: true,
        skipHeader: true,
      },
    },
  },

  Actual: {
    title: 'Aktuális',
    mobileOnly: true,
    sitemap: {
      disabled: true,
      slug: 'aktualis',
    },
    route: {
      path: '/aktualis/:page?',
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'aktualis' } },
      },
    },
  },

  Activities: {
    title: 'Tevékenységeink',
    sitemap: {
      disabled: true,
      slug: 'tevekenysegeink',
    },
    route: {
      path: '/tevekenysegeink/:page?',
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'tevekenysegeink' } },
      },
    },
  },

  Category: {
    title: 'Kategóriák',
    route: {
      path: '/kategoria/:category/:page?',
      exact: true,
    },
    component: {
      type: 'CategoryPage',
    },
  },

  Tag: {
    title: 'Cimkék',
    route: {
      path: '/cimke/:tag/:page?',
      exact: true,
    },
    component: {
      type: 'TagPage',
    },
  },

  Gallery: {
    title: 'Galéria',
    route: {
      path: '/galeria/:slug?',
    },
    component: {
      type: 'GalleryPage',
    },
  },

  Search: {
    title: 'Keresés',
    route: {
      path: '/kereses',
      exact: true,
    },
    component: {
      type: 'SearchPage',
    },
  },

  Post: {
    title: 'Bejegyzés',
    route: {
      path: '/:post',
    },
    component: {
      type: 'PostPage',
    },
  },

};

export default Pages;
