import React from 'react';
import { NavLink } from 'react-router-dom';

import { Pages } from 'consts';
import { Menu } from 'view/components';

import './Footer.scss';
import './Footer.responsive.scss';

// const { REACT_APP_VERSION, REACT_APP_RELEASE_DATE } = process.env;

const topPages = [Pages.Contact];

const bottomPages = [Pages.AboutUs, Pages.Impressum, Pages.PrivacyInfo];

const Footer = (/* props */) => (
  <>
    <div class="footer-prepend">
      <img src="/resources/images/nea.png" alt="Nemzeti Együttműködési Alap" />
      <img src="/resources/images/bethlen.png" alt="Bethlen Gábor Alapkezelő Zrt." />
      <img src="/resources/images/miniszterelnokseg.png" alt="Miniszterelnökség" />
    </div>
    <footer className="footer">
      <Menu className="footer-top" items={topPages} />
      <div className="footer-middle">     
        <NavLink to="/">
          <img src="/resources/images/logo-white.png" alt="logo" />
        </NavLink>
      </div>
      <Menu className="footer-bottom" items={bottomPages} />
    </footer>
  </>
);

export default Footer;
