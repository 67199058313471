import React, { createRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useCategories } from 'contexts';
import { calcCategoryColor } from 'utils';

import Post from '../Post/Post';

import './CategoryPosts.scss';

const CategoryPosts = ({ category }) => {
  const [height, setHeight] = useState('auto');

  const ref = createRef();

  const onToggleClick = useCallback((event) => {
    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }

    setHeight((prevHeight) => (prevHeight ? 0 : ref.current.scrollHeight));
  }, [ref]);

  const { categories } = useCategories();
  const color = calcCategoryColor(category, categories);
  const { name, posts } = category;
  return (
    <div className="category-posts">
      <div role="button" className="category" onClick={onToggleClick} tabIndex={0} onKeyPress={onToggleClick}>
        <div className="category-name" style={{ color }}>{name}</div>
        <div className="menu-arrow material-icons">{height ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</div>
      </div>
      <div ref={ref} className="posts" style={{ height }}>
        {posts && posts.map((post) => <Post key={post.slug} className="post-link" post={post} imageSize="categories" />)}
      </div>
    </div>
  );
};

CategoryPosts.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    posts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default CategoryPosts;
