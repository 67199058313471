/**
 * This file contains constats which are shared between the api and admin layer
 *
 * If the content of this file is changed do not forget to copy it to the `admin/consts/shared.js` and vice versa.
 */
export { default as Pages } from './pages';

export const POST_MAX_PRIORITY = 55;

export const AUTH_COOKIE_NAME = 'AUTH';
export const SEGMENT_COOKIE_NAME = 'SEGMENT';

export const FACEBOOK_APP_ID = 1579669822181418; // production
// export const FACEBOOK_APP_ID = 918158841972826; // development

export const PASSWORD_CHANGE_URL = '/jelszo-modositas/:token';

export const Validator = {
  email: (email) => (new RegExp([
    '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|',
    '(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|',
    '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  ].join(''))).test(email),
};

export const BannerExpiration = {
  NEVER: 'NEVER',
  ONE_DAY: 'ONE_DAY',
  ONE_WEEK: 'ONE_WEEK',
  TWO_WEEKS: 'TWO_WEEKS',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  ONE_YEAR: 'ONE_YEAR',
};

export const BannerStatus = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const CategoryStatus = {
  HIDDEN: 'HIDDEN',
  CLOSED: 'CLOSED',
  OPENED: 'OPENED',
};

export const GalleryStatus = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const JobStatus = {
  INIT: 'INIT',
  RUN: 'RUN',
  CANCEL: 'CANCEL',
  CANCELLED: 'CANCELLED',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export const MediaType = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  OTHER: 'OTHER',
};

export const MediaResourceType = {
  POST: 'POST',
  PROGRAM: 'PROGRAM',
};

export const MediaStatus = {
  WAITING: 'WAITING', // media is not yet available, but the resource is not yet available (needs downloading)
  DOWNLOAD: 'DOWNLOAD', // media is not yet available, but it is started to download
  OK: 'OK', // media stored and the resource is available
};

export const MsgType = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export const PostContentMode = {
  WYSIWYG: 'WYSIWYG',
  HTML: 'HTML',
};

export const PostStatus = {
  DRAFT: 'DRAFT', // the post is under editing
  READY: 'READY', // post is ready for approval
  APPROVE_ON: 'APPROVE_ON', // approval post schedule at a specific time see `apporveOn` field
  APPROVED: 'APPROVED', // post can be shown on the portal
  NOT_APPROVED: 'NOT_APPROVED', // post is not approved and thrown back by the EDITOR
  REVOKED: 'REVOKED', // the must not shown anymore on the portal
};

export const PostType = {
  ARTICLE: 'ARTICLE',
  FAQ: 'FAQ',
  SURVEY: 'SURVEY',
  TECHNICAL: 'TECHNICAL',
};

export const PreferenceType = {
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  MULTILINE: 'MULTILINE',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
};

export const PriorityMode = {
  REORDER: 'REORDER',
  OVERWRITE: 'OVERWRITE',
  STICKY_REORDER: 'STICKY_REORDER',
  STICKY_OVERWRITE: 'STICKY_OVERWRITE',
};

export const RealSegment = {
  PORTAL: 'PORTAL',
};

export const Repeat = {
  NEVER: 'NEVER',
  ALWAYS: 'ALWAYS',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  CUSTOM: 'CUSTOM',
};

export const RepeatTimesInMin = {
  [Repeat.NEVER]: null,
  [Repeat.ALWAYS]: 0,
  [Repeat.HOURLY]: 60,
  [Repeat.DAILY]: 24 * 60,
  [Repeat.WEEKLY]: 7 * 24 * 60,
  [Repeat.MONTHLY]: 30 * 24 * 60,
};

export const Role = {
  GUEST: 'GUEST', // represents a temporary registered guest user
  USER: 'USER',
  WRITER: 'WRITER',
  EDITOR: 'EDITOR',
  SUPER: 'SUPER',
  ADMIN: 'ADMIN',
  TECH: 'TECH',
};

Role.$GENERIC = [null, Role.GUEST, Role.USER, Role.WRITER, Role.EDITOR, Role.SUPER, Role.ADMIN]; // all non-technical
Role.$WRITERS = [Role.WRITER, Role.EDITOR, Role.SUPER, Role.ADMIN]; // at least editor privilege
Role.$EDITORS = [Role.EDITOR, Role.SUPER, Role.ADMIN]; // at least editor privilege
Role.$SUPERS = [Role.SUPER, Role.ADMIN]; // at least SUPER privilege
Role.$ADMINS = [Role.ADMIN]; // at least ADMIN privilege
Role.$REGISTERED = [Role.USER, Role.WRITER, Role.EDITOR, Role.SUPER, Role.ADMIN]; // registered, but non=technical
Role.$UNREGISTERED = [null, Role.GUEST, Role.TECH]; // not-registered with technical
Role.$ALL = Object.keys(Role); // all available except anonymous

export const Segment = {
  ALL: 'ALL',
  $GENERIC: '$GENERIC',
  PORTAL: 'PORTAL',
};

export const SegmentUrl = {
//  [Segment.PORTAL]: '/',
};

export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SubscriptionStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DISABLED: 'DISABLED',
};

export const SubscriptionType = {
  NEWSLETTER: 'NEWSLETTER',
  FATHER_CONFERENCE: 'FATHER_CONFERENCE',
};

export const SurveyType = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  SINGLE_OPT: 'SINGLE_OPT',
  MULTI_OPT: 'MULTI_OPT',
  MULTI_2: 'MULTI_2',
  MULTI_2_OPT: 'MULTI_2_OPT',
  MULTI_3: 'MULTI_3',
  MULTI_3_OPT: 'MULTI_3_OPT',
};

export const SurveyTypeConfig = {
  [SurveyType.SINGLE]: { minCount: 1, maxCount: 1 },
  [SurveyType.MULTI]: { minCount: 1 },
  [SurveyType.MULTI_2]: { minCount: 1, maxCount: 2 },
  [SurveyType.MULTI_3]: { minCount: 1, maxCount: 3 },
  [SurveyType.SINGLE_OPT]: { minCount: 0, maxCount: 1 },
  [SurveyType.MULTI_OPT]: { minCount: 0 },
  [SurveyType.MULTI_2_OPT]: { minCount: 0, maxCount: 2 },
  [SurveyType.MULTI_3_OPT]: { minCount: 0, maxCount: 3 },
};

export const TagStatus = {
  NOT_APPROVED: 'NOT_APPROVED',
  APPROVED: 'APPROVED',
};

export const UserStatus = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};
