import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { generatePath, NavLink } from 'react-router-dom';
import { range } from 'lodash';

import { Button } from 'view/base';

import './Pagination.scss';

const Pagination = ({ className, basePath, pathParams, pages, page }) => (
  <div className={clsx(className, 'pagination')}>
    {range(Math.max(1, +page - 3), Math.min(pages, +page + 3) + 1).map((pageIter) => (
      <NavLink key={pageIter} to={generatePath(basePath, { ...pathParams, page: pageIter })} className="page-item">
        <Button className="grey" outlined={+page !== +pageIter}>{pageIter}</Button>
      </NavLink>
    ))}
  </div>
);

Pagination.propTypes = {
  className: PropTypes.string,
  basePath: PropTypes.string.isRequired,
  pathParams: PropTypes.shape({}),
  pages: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Pagination.defaultProps = {
  className: '',
  pathParams: {},
  page: 1,
};

export default Pagination;
