import { SurveyType } from './index';

const htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: '\'',
};

export const decodeHtml = (html) => html.replace(/&([^;]+);/g, (entity, entityCode) => {
  let match;

  if (entityCode in htmlEntities) {
    return htmlEntities[entityCode];
  } if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) { // eslint-disable-line no-cond-assign
    return String.fromCharCode(parseInt(match[1], 16));
  } if (match = entityCode.match(/^#(\d+)$/)) { // eslint-disable-line no-cond-assign
    return String.fromCharCode(~~match[1]); // eslint-disable-line no-bitwise
  }
  return entity;
});

export const fromJson = (json, defaultValue = null) => {
  try {
    return JSON.parse(json);
  } catch (ex) {
    return defaultValue;
  }
};

export const processSurvey = (content) => {
  const result = [];
  let search = content;
  while (search) {
    const matches = search.match(/(<h3(?:\s.*?)?>(?:.+?)<\/h3>)(.+?)(?:<h3>|$)/i);
    if (!matches) {
      break;
    }
    const { 1: title, 2: rest, index, length } = matches;
    const subtitles = [];
    const answers = [];
    const questionIdx = result.length;
    rest.replace(/<p(?:\s.*?)?>(.+?)<\/p>/ig, (paragraph, text) => {
      const answerIdx = answers.length;
      if (text.startsWith('-')) {
        answers.push({ questionIdx, answerIdx, key: `${questionIdx}-${answerIdx}`, text: text.substr(1).trim() });
      } else if (!answerIdx) {
        subtitles.push(paragraph);
      }

      return '';
    });

    if (answers.length) {
      const { 1: type } = title.match(/\[TYPE=(.+?)\]/i) || [];
      result.push({
        title: title.replace(/\[TYPE=(.+?)\]/i, ''),
        subtitle: subtitles.join(''),
        type: SurveyType[type] || SurveyType.SINGLE,
        answers,
      });
    }

    search = search.substr(index + length);
  }
  return result;
};

const defaultGetter = (vars, key) => vars[key];
export const replaceVars = (str, vars, getter = defaultGetter) => str.replace(/\{(.+?)\}/gm, (entry, key) => getter(vars, key) || entry);
