import { each, findIndex, isNil, shuffle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Like } from 'react-facebook';
import ReactMarkdown from 'react-markdown';

import { SubscriptionType, HOST_URL, Segment } from 'consts';
import { useCategories, useDynamicModules, usePost, useSegment } from 'contexts';
import { calcCategoryColor, getCroppingImageStyles, getDisplayDate, getImageUrl, getPostCreator, getTagUrl } from 'utils';
import { PostImage, PostRedirect } from 'view/base';
import { CategoryBreadcrumb, Post, Subscription, Survey } from 'view/components';
import { Gallery, MainView } from 'view/modules';

import VoteButton from './VoteButton';
import VotePosts from './VotePosts';

import './PostPage.scss';
import './PostPage.responsive.scss';
import './PostPage.editor.scss';

const POST_SLUG_NEWSLETTER_SUBSCRIPTION = 'hirlevel-feliratkozas-info';
const POST_SLUG_NEWSLETTER_SUBSCRIPTION_SUCCESS = 'hirlevel-feliratkozas-sikeres';

const allowLike = false;
const allowVote = false;
const allowSubscription = false;

const modulesMap = {
  POLL: VotePosts,
  SUBSCRIPTION: Subscription,
  SURVEY: Survey,
  MARKDOWN: ReactMarkdown,
};

const imageSizes = [{
  col: 2,
  crop: 'wide',
}];

const renderRelatedPosts = (related) => !!related.length && (
  <section className="related-posts">
    <div className="head">KAPCSOLÓDÓ</div>
    {shuffle(related).slice(0, 3).map((relatedPost, idx) => (
      <Post key={relatedPost.id} className={clsx('related-post', { even: idx % 2 })} post={relatedPost} imageSize="related" crop="narrowCrop" />
    ))}
  </section>
);

const injectImageWrappers = (content, gallery) => {
  if (!gallery?.media) {
    return content;
  }

  const injectedContent = (content || '').replace(/<img(?:.+?)>/ig, (img) => {
    const [, imageId] = img.match(/\/api\/image\/(\d+)/i) || [];
    const imageIdx = findIndex(gallery.media, ({ id }) => id === +imageId);
    if (imageIdx < 0) {
      return img;
    }

    const [, style = ''] = img.match(/style="(.+?)"/) || [];
    return `<div class="gallery-image" data-image-id="${imageId}" data-image-idx="${imageIdx}" style="${style}">${img.replace(/style="(?:.+?)"/i, '')}</div>`;
  });

  return injectedContent;
};

const PostPage = ({ className, history, location, match, code, skipBreadcumb, skipHeader, skipBackground }) => {
  const { activeSegment } = useSegment();
  const { pathname } = location;
  const { params: { post: slug } } = match;
  const { categories = [] } = useCategories();
  const { post, related = [], error, clearCache } = usePost(slug);
  const [galleryItem, setGalleryItem] = useState(null);

  const postId = post && post.id;
  // clear item from cache when component is unmounted
  useEffect(() => () => {
    if (postId) {
      clearCache();
    }
  }, [postId, clearCache]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    title, lead, content, attributes, contentMode, approvedAt, updatedAt, createdAt, redirectUrl, segment,
    image, imageStyles, categories: { 0: category } = [], tags = [],
    gallery,
  } = post || {};
  const externalProps = useMemo(() => ({
    postId, onRedirect: (url) => history.replace(url),
  }), [postId, history]);
  const { html: contentHtml, modules, ref } = useDynamicModules(content, { main: true, externalProps, modulesMap, contentMode });

  const onGalleryItemClick = useCallback((event) => {
    setGalleryItem(+event.currentTarget.dataset.imageIdx);
    document.querySelector('html').classList.add('no-scroll');
  }, []);

  const onGalleryClose = useCallback((/* event */) => {
    setGalleryItem(null);
    document.querySelector('html').classList.remove('no-scroll');
  }, []);

  const contentRef = useRef();
  useEffect(() => {
    if (!contentRef.current || !gallery?.media) {
      return undefined;
    }
    const imageList = [];
    const imageWrappers = contentRef.current.querySelectorAll('[data-image-id]');
    each(imageWrappers, (wrapper) => {
      wrapper.addEventListener('click', onGalleryItemClick);
      imageList.push(wrapper);
    });

    return () => {
      if (imageList) {
        each(imageList, (wrapper) => wrapper.removeEventListener('click', onGalleryItemClick));
      }
    };
  }, [gallery, onGalleryItemClick]);

  // check if post is found or not...
  if (post === null || error) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (!post) {
    return (
      <MainView className={clsx({ 'bg-post': !skipBackground })} loaded={false}>
        <div className={clsx(className, 'post-page')} />
      </MainView>
    );
  }

  if (post && ((segment !== Segment.$GENERIC && activeSegment !== segment) || redirectUrl)) {
    return <PostRedirect post={post} />;
  }

  const pageUrl = `${HOST_URL}${pathname}`;

  const creator = post && getPostCreator(post);
  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);
  const hasSubscriptionModule = !!modules.find(({ moduleName }) => moduleName === 'SUBSCRIPTION');
  const categoryColor = calcCategoryColor(category, categories);

  return (
    <MainView className={clsx({ 'bg-post': !skipBackground })} loaded>
      <div className={clsx(className, 'post-page')} ref={ref}>
        {post && (
        <div className="page-inner">
          <Helmet>
            <title>{title} - europasziveben.hu</title>
            <meta name="description" content={attributes?.description || lead} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="hu_HU" />
            <meta property="og:site_name" content="europasziveben.hu" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={attributes?.description || lead} />
            <meta property="og:url" content={pageUrl} />
            {!!image && (
              <meta
                property="og:image"
                content={`${HOST_URL}${getImageUrl(image, getCroppingImageStyles(imageStyles, 'narrowCrop'), 'facebook')}`}
              />
            )}
            {!!image && HOST_URL.startsWith('https://') && (
              <meta
                property="og:image:secure_url"
                content={`${HOST_URL}${getImageUrl(image, getCroppingImageStyles(imageStyles, 'narrowCrop'), 'facebook')}`}
              />
            )}
            {!!(image && image.contentType) && <meta property="og:image:type" content={image.contentType} />}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={attributes?.description || lead} />
            <meta name="twitter:image" content={`${HOST_URL}${getImageUrl(image, getCroppingImageStyles(imageStyles, 'wideCrop'), 'twitter')}`} />
          </Helmet>
          <article className="post">
            {!skipHeader && (
              <header>
                {image && imageSizes.map(({ col, crop }) => <PostImage key={col + crop} image={image} imageStyles={imageStyles} crop={crop} col={col} />)}
                {!image && <div className="spacer no-image" />}
                <div className="details">
                  {category && !skipBreadcumb && <CategoryBreadcrumb categories={categories} category={category} />}
                  <h1 className="title" style={{ color: categoryColor }}>{title}</h1>
                  <h3 className="info">
                    {!!displayDate && <div className="created-at">{displayDate}</div>}
                    {!!creator && <div className="created-by">ÍRTA: {creator}</div>}
                  </h3>
                  <h2 className="lead">{lead}</h2>
                </div>
                <div className="spacer" />
              </header>
            )}
            <div
              ref={contentRef}
              className="ql-editor content"
              dangerouslySetInnerHTML={{ __html: injectImageWrappers(contentHtml, gallery) } /* eslint-disable-line react/no-danger */}
            />
            <div className="clear" />
            {!!tags.length && (
              <div className="tags">
                {tags.map((tag) => (
                  <NavLink key={tag.id} to={getTagUrl({ slug: tag.slug, segment: activeSegment })} className="item" style={tag.styles}>
                    {tag.name}
                  </NavLink>
                ))}
              </div>
            )}
            {(allowLike || allowVote) && (
            <div className="vote-like-and-share">
              {allowVote && <VoteButton post={post} />}
              {allowLike && <Like href={pageUrl} layout="button_count" colorScheme="dark" size="large" showFaces="false" share />}
            </div>
            )}
          </article>
          {renderRelatedPosts(related)}
          {allowSubscription && category && !hasSubscriptionModule && (
            <Subscription
              type={SubscriptionType.NEWSLETTER}
              postSlug={POST_SLUG_NEWSLETTER_SUBSCRIPTION}
              postSlugSuccess={POST_SLUG_NEWSLETTER_SUBSCRIPTION_SUCCESS}
              button="Feliratkozom"
              newsletter
              newsletterRequired
            />
          )}
          {code && <div className="page-code" dangerouslySetInnerHTML={{ __html: code }} />} {/* eslint-disable-line react/no-danger */}
        </div>
        )}
        {gallery && !isNil(galleryItem) && (
          <Gallery
            gallery={{ ...gallery, title: gallery.title || title }}
            selectedItem={galleryItem}
            onClose={onGalleryClose}
          />
        )}
      </div>
    </MainView>
  );
};

PostPage.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  className: PropTypes.string,
  code: PropTypes.string,
  skipBreadcumb: PropTypes.bool,
  skipHeader: PropTypes.bool,
  skipBackground: PropTypes.bool,
};

PostPage.defaultProps = {
  className: '',
  code: undefined,
  skipBreadcumb: false,
  skipHeader: false,
  skipBackground: false,
};

export default PostPage;
