import { map, merge } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FacebookProvider } from 'react-facebook';

import {
  Pages, FACEBOOK_APP_ID, IS_BROWSER,
} from 'consts';
import { ToggledCategoryProvider, usePosts, useSegment } from 'contexts';
import { getQueryParam, getSearchUrl } from 'utils';
import { CookiePopup } from 'view/components';
import { Footer, Header } from 'view/modules';
import * as PageComponents from 'view/pages';

import Popup from './Popup';

import './App.scss';
import './App.responsive.scss';

const allowCookiePopup = false;

// const getStoredA11y = () => {
//   try {
//     return window && window.localStorage && JSON.parse(window.localStorage.getItem('a11y'));
//   } catch (ex) {
//     return false;
//   }
// };

const scrollToTop = () => IS_BROWSER && window.scrollTo(0, 0);

const renderRoute = (page, key) => {
  const { component, route } = page;

  const Page = PageComponents[component?.type];
  if (!Page) {
    throw Error(`Page not found: ${key} / ${component?.type}!`);
  }
  if (!route) {
    throw Error(`Route not found: ${key}!`);
  }

  return <Route key={key} {...route} render={(routeProps) => <Page {...merge(routeProps, component?.props || {})} />} />;
};

const App = () => {
  // const [a11yEnabled, setA11yEnabled] = useState(getStoredA11y());

  const { clearCache } = usePosts();
  const { pathname, search } = useLocation();

  useEffect(() => {
    scrollToTop();
    const focused = document.querySelector(':focus');
    if (focused) {
      focused.blur();
    }
    /*
    // facebook & ga tracking
    const { ga, fbq } = window;
    if (typeof ga === 'function') {
      ga('set', 'page', pathname + search);
      ga('send', 'pageview');
    }

    fbq('track', 'ViewContent');
    */
  }, [pathname, search]);

  // const onA11yClick = useCallback((/* event */) => {
  //   setA11yEnabled((a11yEnabledPrev) => {
  //     if (window && window.localStorage) {
  //       window.localStorage.setItem('a11y', JSON.stringify(!a11yEnabledPrev));
  //     }
  //     return !a11yEnabledPrev;
  //   });
  // }, []);

  const onLogoClick = useCallback((/* event */) => {
    clearCache();
    if (window && window.localStorage) {
      window.localStorage.setItem('a11y', JSON.stringify(false));
    }
    // setA11yEnabled(false);
  }, [clearCache]);

  const { activeSegment, setActiveSegment, defaultSegment } = useSegment();

  // search via goolge search
  if (pathname === '/') {
    const searchQuery = getQueryParam(search, 's');
    if (searchQuery) {
      return <Redirect to={getSearchUrl({ s: searchQuery })} />;
    }
    if (activeSegment !== defaultSegment) {
      setActiveSegment(defaultSegment);
    }
  }

  return (
    <FacebookProvider appId={FACEBOOK_APP_ID}>
      <Helmet>
        <html lang="hu" prefix="og: http://ogp.me/ns/website# fb: http://ogp.me/ns/fb#" />
        <title>Európa Szívében Alapítvány</title>
      </Helmet>
      <ToggledCategoryProvider>
        <div className="app">
          <Header showSearch onLogoClick={onLogoClick} /> {/* onA11yClick={onA11yClick} */}
          <Switch>
            {map(Pages, renderRoute)}
            <Redirect to="/" />
          </Switch>
          <Footer />
          {/* <MobileMenu a11yEnabled={a11yEnabled} onA11yClick={onA11yClick} /> */}
        </div>
      </ToggledCategoryProvider>
      <Popup />
      {allowCookiePopup && <CookiePopup />}
    </FacebookProvider>
  );
};

export default App;
