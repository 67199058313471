/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect } from 'react-router-dom';

import { Pages } from 'consts';
import { usePost, useSubscription } from 'contexts';
import { Button } from 'view/base';

import './Subscription.scss';

const Checkbox = typeof window !== 'undefined' ? require('react-simple-checkbox').default : () => '';

// eslint-disable-next-line max-len
const regexpEmailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const messageMap = {
  'validation.error.not-unique': 'Ezzel az e-mail címmel már fel vagy iratkozva!',
};

const getError = (error) => {
  const message = error && error.messages && error.messages[0];
  const name = message && message.name;
  return (messageMap[name]) || `A feliratkozás rendszerporbléma miatt jelenleg nem működik (${name || 'ismeretlen kód'})!`;
};

const onPrivacyInfoClick = (event) => {
  event.preventDefault();
  window.open(generatePath(Pages.PrivacyInfo.route.path), 'previewWindow', 'menubar=0,toolbar=0,width=1280,height=768');
};

const Subscription = ({ type, postSlug, postSlugSuccess, newsletter, newsletterRequired, button, onRedirect }) => {
  const [email, setEmail] = useState('');

  const [privactyInfoRead, setPrivactyInfoRead] = useState(false);
  const onPrivacyInfoRead = useCallback((/* ev */) => {
    setPrivactyInfoRead(!privactyInfoRead);
  }, [privactyInfoRead, setPrivactyInfoRead]);

  const [allowReceivingNewsletter, setAllowReceivingNewsletter] = useState(false);
  const onAllowReceivingNewsletterChange = useCallback((/* ev */) => {
    setAllowReceivingNewsletter(!allowReceivingNewsletter);
  }, [allowReceivingNewsletter, setAllowReceivingNewsletter]);

  const { loading, error, subscription, subscribeByEmail } = useSubscription();
  const onSubscriptionSubmit = useCallback(async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    subscribeByEmail({ type, email, newsletter });
  }, [subscribeByEmail, type, email, newsletter]);

  const { post } = usePost(postSlug);

  useEffect(() => {
    if (!subscription || !onRedirect) {
      return;
    }
    onRedirect(postSlugSuccess ? generatePath(Pages.SubscriptionSuccess.route.path, { post: postSlugSuccess }) : '/');
  }, [subscription, postSlugSuccess, onRedirect]);

  if (subscription && !onRedirect) {
    return <Redirect to={postSlugSuccess ? generatePath(Pages.SubscriptionSuccess.route.path, { post: postSlugSuccess }) : '/'} />;
  }

  const isValid = regexpEmailValidator.test(email);
  const disabled = !privactyInfoRead || (newsletter && newsletterRequired && !allowReceivingNewsletter) || !isValid || loading;
  return (
    <div className="subscription">
      {post && (
        <div className="post subscription-post">
          <div className="title">{post.title}</div>
          <div className="lead">{post.lead}</div>
          <div className="ql-editor content" dangerouslySetInnerHTML={{ __html: post.content }} /> {/* eslint-disable-line react/no-danger */}
        </div>
      )}
      <form onSubmit={onSubscriptionSubmit}>
        <input type="text" disabled={loading} placeholder="E-mail cím..." name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
        {error && <div className="error">{getError(error && error.response && error.response.data)}</div>}
        <div className="privacy-check-item">
          <Checkbox id="privactyInfoRead" className="privacy-checkbox" checked={privactyInfoRead} onChange={onPrivacyInfoRead} size={4} />
          <label className="label" htmlFor="privactyInfoRead"> {/* eslint-disable-line */}
            Az <a href="#" onClick={onPrivacyInfoClick} target="_blank" rel="noopener noreferrer">adatkezelési tájékoztatót</a> elolvastam és megismertem
          </label>
        </div>
        {newsletter && (
          <div className="privacy-check-item">
            <Checkbox
              id="allowReceivingNewsletters"
              className="privacy-checkbox"
              checked={allowReceivingNewsletter}
              onChange={onAllowReceivingNewsletterChange}
              size={4}
            />
            <label className="label" htmlFor="allowReceivingNewsletters"> {/* eslint-disable-line */}
              Hozzájárulok, hogy az Adatkezelő hírlevelet küldjön részemre
            </label>
          </div>
        )}
        {button && <Button className="subscribe-button green text" disabled={disabled} alt="Feliratkozom" onClick={onSubscriptionSubmit}>{button}</Button>}
      </form>
    </div>
  );
};

Subscription.propTypes = {
  type: PropTypes.string.isRequired,
  postSlug: PropTypes.string,
  postSlugSuccess: PropTypes.string,
  button: PropTypes.string,
  newsletter: PropTypes.bool,
  newsletterRequired: PropTypes.bool,
  onRedirect: PropTypes.func,
};

Subscription.defaultProps = {
  postSlug: undefined,
  postSlugSuccess: undefined,
  button: undefined,
  newsletter: false,
  newsletterRequired: false,
  onRedirect: undefined,
};

export default Subscription;
