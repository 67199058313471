import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, matchPath, useLocation } from 'react-router';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import './Menu.scss';

const Menu = ({ className, items }) => {
  const location = useLocation();
  return (
    <div className={clsx('menu', className)}>
      {items.map(({ title, route, mobileOnly }) => {
        const active = matchPath(location.pathname, route);
        return (
          <NavLink
            key={title}
            className={clsx('menu-item', { active, 'mobile-only': mobileOnly })}
            to={generatePath(route.path)}
          >
            {title}
          </NavLink>
        );
      })}

    </div>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
