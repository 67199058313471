import React, { useCallback, useMemo } from 'react';
import { find, map } from 'lodash';
import PropTypes from 'prop-types';
import { generatePath, NavLink, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { useGalleries } from 'contexts';
import { Gallery, MainView } from 'view/modules';

import './GalleryPage.scss';
import clsx from 'clsx';
import { getImageUrl } from 'utils';

const GalleryPage = ({ className, match }) => {
  const { galleries, error } = useGalleries();

  const { params: { slug: gallerySlug }, path } = match;
  const selectedGallery = useMemo(() => find(galleries || [], (gallery) => gallery.slug === gallerySlug), [galleries, gallerySlug]);

  const renderGalleryItem = useCallback(({ media, title, slug }) => {
    if (!media?.length) {
      return '';
    }

    return (
      <NavLink className="gallery-item" to={generatePath(path, { slug })}>
        <img src={getImageUrl(media[0], false, 'gallery')} alt={title} />
        <div className="title">{title}</div>
      </NavLink>
    );
  }, [path]);

  if (galleries === null || error) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (galleries && !selectedGallery && gallerySlug) {
    return <Redirect to={generatePath(path)} />;
  }

  return (
    <MainView className={clsx({ 'bg-gallery': selectedGallery })} loaded={!!galleries}>
      <div className={clsx('gallery-page', className, { list: !selectedGallery })}>
        {!selectedGallery && galleries && map(galleries, renderGalleryItem)}
        {selectedGallery && <Gallery gallery={selectedGallery} />}
      </div>
    </MainView>
  );
};

GalleryPage.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,

};

GalleryPage.defaultProps = {
  className: '',

};

export default GalleryPage;
